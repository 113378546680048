import React, { useState } from "react";
import { Link } from "react-router-dom";
import { menuItemstwo } from "../menuitems/menuItems";
import "./dropdowntwo.css";

function Dropdowntwo() {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <ul className={dropdown ? "about-submenu-clicked" : "about-submenu"}>
        {menuItemstwo.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.cname}
                onClick={() => setDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
    // onClick={()=>setDropdown(!dropdown)}
  );
}

export default Dropdowntwo;
