import React, {useState} from 'react'
import { FaClock } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import Logo from "../resources/images/logo.png";
import { Link } from "react-router-dom";
import './time.css'

function Time() {
    const [isNavShowing, setIsNavShowing] = useState(false);
  return (
    <div className="top-container">
          <div className="display">
            <Link
              to="/"
              className="logo"
              onClick={() => setIsNavShowing(false)}
            >
              <img src={Logo} alt="Tribunal Logo" />
            </Link>
          </div>
          <div className="display">
            <FaClock className="display-icons" />
            <div className="display-line">
              <h2>working hours</h2>
              <h4>Mon-Fri: 8am-5pm</h4>
            </div>
          </div>
          <div className="display">
            <FaLocationDot className="display-icons" />
            <div className="display-line">
              <h2>Office location</h2>
              <h4>Milimani Annex, 4th Floor | Upper Hill Close</h4>
            </div>
          </div>
          <div className="display">
            <IoCall className="display-icons" />
            <div className="display-line">
              <h2>call us</h2>
              <h4>+254-730181895/91</h4>
            </div>
          </div>
          <div className="display">
            <MdEmail className="display-icons" />
            <div className="display-line">
              <h2>email us</h2>
              <h4>ort@court.go.ke</h4>
            </div>
          </div>
          <div className="display">
                 <div className='button-top'>
                      <Link className="button-link" to="http://causelist.court.go.ke/"   target="_blank"> Causelist </Link> 
                 </div>
             </div>
        </div>
  )
}

export default Time