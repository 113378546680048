import React from 'react'
import { tribunalsInfo } from '../../components/resources/data/data'
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import Card from '../ui/Card'
import Header from '../../components/headertwo/Header'
import './jurisdiction.css'
import Dropdown from '../../components/dropdown/Dropdown';

const Jurisdiction = () => {
    const [description, setDescription] = useState([]);
    const [membersNo, setMembersNo] = useState("");
    const [image, setImage] = useState("");
    const [tribunalName, setTribunalName] = useState("");
    const [chairman, setChairman] = useState("");
    const [members, setMembers] = useState([]);
    const [jurisdictionName, setJurisdictionName] = useState([]);
    const [location, setLocation] = useState("");
    const [qrcode, setQrcode] = useState("");
    const [email, setEmail] = useState("");
    const [id, setId] = useState(useParams().id);
    useEffect(() => {
        for (var i = 0; i < tribunalsInfo.length; i++) {
            var obj = tribunalsInfo[i];
           
            if (obj.id == id) {
                setId(obj.id);
                setImage(obj.image);
                setTribunalName(obj.tribunalNmae)
                setDescription(obj.description);
                setChairman(obj.chairman)
                setMembersNo(obj.membersNo);
                setMembers(obj.members)
                setJurisdictionName(obj.jurisdiction)
                setQrcode(obj.qrcode)
                setLocation(obj.location)
                setEmail(obj.email)
            }
          
        }
    });
    return (
        <>
            <div className='jurisdiction_image'>
                <img src={image} />
            </div>
            <section className="jurisdiction_plans">
                <div className='jurisdiction_container-left_box'>
                    <h2 className='tribename' >{tribunalName}</h2>
                    <h2 className='tribenames'> ChairPerson</h2>
                    <h3>{chairman}</h3>
                    <h2 className='tribenamess'>Current Members</h2>
                    {
                        members.map(({id, memberName})=>{
                            return<ul className="list_members"key ={id}>
                                <li className='list_member'>{memberName}</li>
                            </ul>
                        })
                    }
                </div>
                <div className="jurisdiction_container-right_box">
                    <h1>Establishment</h1>
                    <h3>{description}</h3>
                    <h2>Number of Members of the Tribunal</h2>
                    <h3>{membersNo}</h3>
                    {/* <h2>ChairPerson</h2>
                    <h3>{chairman}</h3>
                    <h2>Current Members</h2>
                    {
                        members.map(({id, memberName})=>{
                            return<ul key ={id}>
                                <li>-{memberName}</li>
                            </ul>
                        })
                    } */}
                    <h2>Jurisdiction</h2>
                    {
                        jurisdictionName.map(({ id, jurisdictionName }) => {
                            //console.log(jurisdictionName)
                            return <ul key={id}>
                                <li>-{jurisdictionName}</li>
                            </ul>
                        })}
                        <h2>Scan to see Judgements</h2>
                        <img className = "qrcode"src={qrcode}/>
                        <h2>Location</h2>
                        <h3>{location}</h3>
                        <h2>Contact</h2>
                        <h3>{email}</h3>
                       <button className='efiling'> <Link to="https://efiling.court.go.ke/" target="_blank">E-Filing</Link></button>
                        
                </div>
                
            </section>
        </>
    )
}
export default Jurisdiction
