export const navItems = [
  {
    id: 1,
    title: "Home",
    path: "./",
    cName: "nav-item",
  },
  {
    id: 2,
    title: "About Us",
    cName: "nav-item",
  },
  {
    id: 3,
    title: "Tribunals",
    cName: "nav-item",
  },
  // {
  //   id: 4,
  //   title: "Know all Tribunals",
  //   path: "./tribunals",
  //   cName: "nav-item",
  // },
  {
    id: 4,
    title: "Media",
    cName: "nav-item",
  },
  {
    id: 5,
    title: "Resources",
    path: "./resources",
    cName: "nav-item",
  },
  {
    id: 6,
    title: "Contacts",
    path: "./contact",
    cName: "nav-item",
  },
];

export const menuItems = [
 
  {
    id:1,
    title: "Tribunals",
    submenu: [
      {
        title: "Commercial Tribunals",
        submenu: [
          {
            title: "Capital Markets Tribunal",
            path:"CapitalMarkets"
          },
          {
            title: "Competition Tribunal",
            path:"CompetitionTribunal"
          },
          {
            title: "Cooperative Tribunal",
            path:"CooperativeTribunal"
          },
          {
            title: "Financial Center Tribunal",
            path:"FinancialCenterTribunal"
          },
          {
            title: "Micro and Small Enterprises Tribunal",
            path:"MicroandSmallEnterprisesTribunal"
          },
          {
            title: "National Civil Aviation Administrative Review Tribunal",
            path:"NationalCivilAviationAdministrativeReviewTribunal"
          },
          {
            title: "Standards Tribunal",
            path:"StandardsTribunal"
          },
          {
            title: "Tax Appeals Tribunal",
            path:"TaxAppealsTribunal"
          },
          {
            title: "Transport Licensing Appeals Board",
            path:"TransportLicensingAppealsBoard"
          },
          {
            title: "Public-Private Partnership Petition Committee",
            path:"Public-PrivatePartnershipCommittee"
          },
        ],
      },
      {
        title: "Civil and Human Rights Tribunals",
        submenu: [
          {
            id:1,
            title: "Education Appeals Tribunal",
            path:"EducationAppealsTribunal"
          },
          {
            title: "Hiv and Aids Tribunal",
            path:"HivandAidsTribunal"
          },
          {
            title: "Legal Education Appeals Tribunal",
            path:"LegalEducationAppealsTribunal"
          },
          {
            title: "National Examinations Appeals Tribunal",
            path:"NationalExaminationsAppealsTribunal"
          },
          {
            title: "Political Parties Disputes Tribunal",
            path:"PoliticalPartiesDisputesTribunal"
          },
          {
            title: "Sports Dispute Tribunals",
            path:"SportsDisputeTribunals"
          },
          {
            title: "Communication and Multi-Media Appeals Tribunal",
            path:"CommunicationandMulti-MediaAppealsTribunal"
          },
        ],
      },
     
      {
        title: "Environment and Land Tribunals",
        submenu: [
          {
            title: "Business Premises Rent Tribunal",
            path:"BusinessPremisesRentTribunal"
          },
          {
            title: "Energy and Petroleum Tribunal",
            path:"EnergyandPetroleumTribunal"
          },
          {
            title: "Land Acquisition Tribunal",
            path:"LandAppealsTribunal"
          },
          {
            title: "National Environment Tribunal",
            path:"NationalEnvironmentTribunal"
          },
          {
            title: "Rent Restrictions Tribunal",
            path:"RentRestrictionsTribunal"
          },
          {
            title: "Water Tribunal",
            path:"WaterTribunal"
          },
        ],
      },
      {
        title: "Intellectual Property Tribunals",
        submenu: [
       
          {
            title: "Copyright Tribunal",
            path:"CopyrightTribunal"
          },
          {
            title: "Industrial Property Tribunal",
            path:"IndustrialPropertyTribunal"
          },
        ],
      },
    ],
  },
  
];

export const menuItemstwo = [
 
  {
    id:1,
         title: "Our Story",
        path:"about",
        cname:"submenu-item"
      },
      {
        id:2,
        title: "The Team",
        path:"team",
        cname:"submenu-item"
      },
    
  
];

export const menuItemsthree = [
      {
        id:1,
        title: "Events",
        path:"events",
        cname:"submenu-item"
      },
      {
        id:2,
        title: "Gallery",
        path:"gallery",
        cname:"submenu-item"
      },
    
    ];



