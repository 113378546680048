import React, { useEffect } from "react";
import asugah from "../../resources/images/Asugah.jpg";
import "./findmore.css";
import knowyourt from "./qr-code.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { PiArrowSquareOutBold } from "react-icons/pi";

function Findmore() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="container registrar-message">
      <div data-aos="fade-up" data-aos-duration="2500" className="findout-more_container">
        <div className="findout-more_container_logo">
          <div className="content-link">
            <Link
              className="link-teams-color"
              to="https://drive.google.com/file/d/1TbwUp8tvSOh9VYad-AIrbkctHiVMW_VY/view"
              target="-blank"
            >
              Find out more
            </Link>{" "}
          </div>
          <img src={knowyourt} />
        </div>
        <div className="findout-more_container_faq">
          <div className="content-link">
            <Link className="link-teams-color" to="/faq">
              <PiArrowSquareOutBold /> Faqs
            </Link>
          </div>
          <p>
            This page provides help with the most common questions about
            Tribunals. <br />
            Your Right To Know
            <br /> Got a question? <br /> Get your answer
          </p>
        </div>
        <div className="findout-more_container_charter">
          <div className="content-link">
            <Link className="link-teams-color" to="/service-charter">
              {" "}
              <PiArrowSquareOutBold />
              Our Commitment
            </Link>
          </div>
          <p>
            The Purpose of this charter is to Outline the service standards
            expected from the Secretariat. We are Committed to providing quality
            services that meet our customers needs. We endevour to Serve with
            integrity, impartiality and Humility
          </p>
        </div>
      </div>
    </section>
  );
}

export default Findmore;
