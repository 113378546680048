import React from 'react'
import Header from '../../components/headertwo/Header'
import HeaderImage from '../../components/resources/images/about-stones.jpg'
import Card from '../../components/ui/Card'
import { Link } from 'react-router-dom'
import { tribunals } from '../../components/resources/data/data'
import './tribunals.css'

const Tribunals = () => {
  return (
    <div>
      <Header title='Tribunals' image={HeaderImage}>
        Tribunals are quasi- Judicial Bodies Established by Various Acts of
        Parliament and Mandated to Hear and Determine Disputes Arising from
        Their Areas of Specialization.
      </Header>

      <section className="plans">
        <div className=" plans_container">
          {
            tribunals.map(({ id, name, description }) => {
              return <div key={id} className='tribunal_plan'>
                <Link to={`/jurisdiction/${id}`} onClick={() => { window.scroll(0, 0); }}>

                  <h3>{name}</h3>
                  <h5>{description}</h5>

                </Link>
                {/* <button className='tribunal_btn'>More Details</button> */}
              </div>
            })
          }
        </div>
      </section>
    </div>
  )
}
export default Tribunals