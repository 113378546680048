import React, { useState } from "react";
import { Link } from "react-router-dom";
import { menuItemsthree } from "../menuitems/menuItems";
import "./dropdownthree.css";

function Dropdownthree() {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <ul className={dropdown ? "media-submenu-clicked" : "media-submenu"}>
        {menuItemsthree.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.cname}
                onClick={() => setDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdownthree;
