import React, { useState } from "react";
import "./navbartwo.css";
import { Link } from "react-router-dom";
import { navItems } from "../menuitems/menuItems";
import Dropdowntwo from "../dropdowntwo/Dropdowntwo";
import { menuItems } from "../menuitems/menuItems";
import MenuItems from "../menuitems/MenuItemss";
import Dropdownthree from "../dropdownthree/Dropdownthree";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import logo from '../resources/images/logo.png'
import Sidebar from '../sidebar/Sidebar'

function Navbartwo() {
  const [dropdown, setDropdown] = useState(false);
  const [dropdowntwo, setDropdowntwo] = useState(false);
  const [isNavShowing, setIsNavShowing] = useState(false);

  
  return (
    <>
      <nav>
        <div className="navbar"f>
        <Link to="/" className="logo" onClick={() => setIsNavShowing(false)}>
            <img src={logo} alt="Tribunal Logo" />
          </Link>
          <ul className={`nav-items ${isNavShowing ? "show_Nav" : "hide_Nav"}`}>
            {navItems.map((item) => {
              if (item.title === "About Us") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onMouseEnter={() => setDropdown(true)}
                    onMouseLeave={() => setDropdown(false)}
                  >
                    <Link to={item.path}>{item.title}</Link>
                    {dropdown && <Dropdowntwo />}
                  </li>
                );
              }
              if (item.title === "Media") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onMouseEnter={() => setDropdowntwo(true)}
                    onMouseLeave={() => setDropdowntwo(false)}
                  >
                    <Link to={item.path}>{item.title}</Link>
                    {dropdowntwo && <Dropdownthree />}
                  </li>
                );
              }
              if (item.title === "Tenders") {
                return (
                  <li className={item.cName}>
                    <Link
                      to="https://judiciary.go.ke/download-category/tenders/"
                      target="_blank"
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              }
              if (item.title === "Tribunals") {
                return (
                  <li>
                    {menuItems.map((menu, index) => {
                      const depthLevel = 0;
                      return (
                        <MenuItems
                          items={menu}
                          key={index}
                          depthLevel={depthLevel}
                        />
                      );
                    })}
                  </li>
                );
              }
              return (
                <li key={item.id} className={item.cName}>
                  <Link to={item.path}>{item.title}</Link>
                </li>
              );
            })}
            <div className="nav-causlist">
            <Link className="nav-button-cause" to="https://causelist.court.go.ke/"   target="_blank">Causelist </Link> 
            </div>
          </ul>
         
          <button
            className="nav_toggle-btn"
            
          >
            {isNavShowing ? <MdOutlineClose /> :   <Sidebar/> }
          </button>
        </div>
      </nav>
    </>
  );
}

export default Navbartwo;
